/* You can add global styles to this file, and also import other style files */

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: "Ubuntu", sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6,
span,
p,
small,
mat-card,
.mat-dialog-title,
div,
input,
table {
	font-family: "Ubuntu", sans-serif !important;
}

.mat-tab-body-content {
	background-color: transparent;
}

.mat-card > h1,
h2,
h3,
h4 {
	font-weight: 500;
}

.mat-fab:not([class*="mat-elevation-z"]),
.mat-mini-fab:not([class*="mat-elevation-z"]) {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1),
		0px 6px 10px 0px rgba(0, 0, 0, 0.02), 0px 1px 18px 0px rgba(0, 0, 0, 0.02) !important;
}

.mat-card-header-text {
	margin: 0 !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
	border-top-right-radius: 0.6rem !important;
	border-top-left-radius: 0.6rem !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
	border-bottom-right-radius: 0.6rem !important;
	border-bottom-left-radius: 0.6rem !important;
}

.form-row {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px 0;
	gap: 1rem;
}
.row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

th.mat-header-cell {
	font-size: 0.8rem;
	font-weight: 600;
}

.fill-space {
	flex-grow: 1;
}

.mat-dialog-container {
	color: #3d4d63 !important;
}

.label-container {
	color: #555;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
}

.label-container .label {
	font-weight: 700;
}

.label-container .content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-field {
	min-width: 200px;
	max-width: 100%;
	flex-grow: 1;
}

/* .form-row .form-field {
	margin-left: 24px;
} */

.form-row .form-radio {
	margin-left: 35px;
}

.form-row .form-radio:nth-child(1) {
	margin-left: 0;
}

.form-row .form-field:nth-child(1) {
	margin-left: 0;
}

.container {
	max-width: 1150px;
	margin: 0rem auto;
	padding: 1.5rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
}

.paddingless-dialog-container .mat-dialog-container {
	padding: 0;
}

.mat-form-field-flex {
	gap: 10px;
}

.button-group {
	display: flex;
	align-items: center;
	gap: 10px;
}

.ellipsis {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.ellipsis-inline {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.system-message {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 100%;
	height: fit-content;
	margin: 0;
	padding: 23px;
	background-color: rgb(142 142 142 / 15%) !important;
	border: 2px solid rgb(137 137 137 / 20%) !important;
	border-radius: 5px;
	text-align: center;
	color: rgb(138 138 138 / 74%) !important;
}

.system-message.short {
	width: fit-content;
	padding: 5px 10px;
}

.mat-card {
	outline: none;
}

.mat-icon {
	vertical-align: middle;
}

.mat-button-wrapper,
.mat-button-toggle-label-content {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.mat-button-wrapper .mat-icon {
	font-size: 24px !important;
}

.mat-form-field-disabled * {
	cursor: not-allowed;
}

*,
*::before,
*::after {
	outline: none;
	box-sizing: border-box;
}

.add-min-height {
	min-height: 5.6rem !important;
}

.add-more-min-height {
	min-height: 12rem !important;
}

.mat-toolbar.toolbar-white .mat-toolbar-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1150px;
	margin: 0 auto;
}

.mat-toolbar.toolbar-white h1 * {
	vertical-align: middle;
}
.mat-toolbar.toolbar-white h1,
.mat-toolbar.toolbar-white h2 {
	display: flex;
	align-items: center;
	gap: 10px;
}

@media (min-width: 1180px) {
}

@media (max-width: 480px) {
	.form-field {
		flex-grow: 1;
		max-width: none;
	}

	.form-row .form-field {
		margin-left: 0;
	}

	.container {
		width: 100%;
		margin: 0;
	}
}

* {
	scrollbar-width: 15px;
	scrollbar-color: #bdbdbd;
	scrollbar-track-color: transparent;
}

*:hover {
	scrollbar-color: #9e9e9e;
}

*:active {
	scrollbar-color: #808080;
}

::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

::-webkit-scrollbar-thumb {
	background-color: #bdbdbd;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #9e9e9e;
}

::-webkit-scrollbar-thumb:active {
	background-color: #808080;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 20px;
	border: 2px solid transparent;
	background-clip: content-box;
}

.ngx-overlay.loading-foreground {
	height: calc(100vh - 48px) !important;
}

.ngx-overlay.loading-foreground {
	height: 100vh !important ;
}

.menu-panel-notification {
	max-height: 300px !important;
}

.search-input .mat-form-field-underline {
	display: none !important;
}

.search-input .mat-form-field-wrapper {
	padding-bottom: 0 !important;
}

.valor-container .mat-form-field-appearance-legacy .mat-form-field-label {
	padding-left: 1.5rem;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
	.mat-form-field-label {
	padding-left: 0 !important;
}

.autocomplete-proposta {
	margin-top: 0.5rem;
	border-radius: 0.5rem;
}

.mat-icon svg {
	width: 1.3rem;
	height: 1.3rem;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
	gap: 0.3rem;
}
