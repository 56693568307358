@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "_variables.scss";

$light-theme: mat-light-theme(
	(
		color: (
			primary: $app-primary,
			accent: $app-accent,
			warn: $app-warn,
		),
	)
);

$dark-theme: mat-dark-theme(
	(
		color: (
			primary: $app-primary,
			accent: $app-accent,
			warn: $app-warn,
		),
	)
);

@include angular-material-theme($light-theme);
@include angular-material-theme($dark-theme);

@include mat.core();
@include mat.all-component-typographies();

@mixin create-dark-theme() {
	@include mat.all-component-themes($dark-theme);
}

@mixin create-light-theme() {
	@include mat.all-component-themes($light-theme);
}

.dark-theme {
	@include create-dark-theme();
}

.light-theme {
	@include create-light-theme();
}

$light-bg-01: whitesmoke;
$light-bg-02: #fff;
$light-bg-03: rgba(245, 245, 245, 0.4);
$light-color: #3d4d63;

$dark-bg-01: #1f2122;
$dark-bg-02: #181a1b;
$dark-bg-03: #8585857d;
$dark-color: #f1f1f1;
$dark-color-01: #cfcfcf;

.main-toolbar-primary {
	background: #1a345a !important;
	color: white !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: rgb(255 255 255 / 20%) !important;
}

.border-radius {
	border-radius: 0.6rem !important;
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
	color: mat-color($app-primary);
}

//LIGHT THEME
.light-theme {
	.scheme-01 {
		background: $light-bg-01 !important;
		color: $light-color;
	}

	.scheme-02 {
		background: $light-bg-02 !important;
		color: $light-color;
	}

	.scheme-03 {
		background: $light-bg-03 !important;
		color: $light-color;
	}

	.scheme-04 {
		border: 2px solid $light-bg-01;
		background: $light-bg-03 !important;
		border-radius: 0.3rem !important;
	}

	.mat-drawer-container {
		background-color: $light-bg-01;
	}

	.mat-toolbar.toolbar-white {
		background-color: $light-bg-01;
	}

	.oportunidades-container {
		background-color: $light-bg-01;
	}

	.card-oportunidade {
		background: $light-bg-03 !important;
		border: 2px solid $light-bg-01;
	}

	.container-search input::placeholder {
		color: #ffffff8e !important;
	}

	.mat-card > h1,
	h1,
	h2,
	h3,
	h4,
	.mat-list-item,
	.form-field span {
		color: $light-color;
	}

	.mat-card {
		background: $light-bg-02;
		color: $light-color;
	}

	.mat-card:not([class*="mat-elevation-z"]),
	.mat-tab-header-pagination {
		box-shadow: none !important;
	}

	.mat-expansion-panel:not([class*="mat-elevation-z"]) {
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
			0px 2px 2px 0px rgba(0, 0, 0, 0.014), 0px 1px 5px 0px rgba(0, 0, 0, 0.012);
	}
}

//Dark-theme

.dark-theme {
	.scheme-01 {
		background: $dark-bg-01 !important;
		color: $dark-color;
	}

	.scheme-02 {
		background: $dark-bg-02 !important;
		color: $dark-color;
	}

	.scheme-03 {
		background: $dark-bg-01 !important;
		color: $dark-color;
	}

	.scheme-04 {
		border: 2px solid $dark-bg-01;
		background: $dark-bg-01 !important;
		border-radius: 0.3rem !important;
	}

	.mat-drawer-container {
		background-color: $dark-bg-01;
	}

	.mat-autocomplete-panel {
		background-color: $dark-bg-01;
	}

	.mat-toolbar.toolbar-white {
		background-color: $dark-bg-01;
	}

	.oportunidades-container {
		background-color: $dark-bg-01;
	}

	.card-oportunidade {
		background: $dark-bg-01 !important;
		border: 2px solid $dark-bg-01;
	}

	.container-search input::placeholder {
		color: #ffffff8e !important;
	}

	.mat-card > h1,
	h1,
	h2,
	h3,
	h4,
	.mat-list-item,
	.form-field span {
		color: $dark-color;
	}

	.mat-card {
		background: $dark-bg-02;
		color: $dark-color-01;
	}

	.mat-card:not([class*="mat-elevation-z"]),
	.mat-tab-header-pagination {
		box-shadow: none !important;
	}

	.mat-expansion-panel:not([class*="mat-elevation-z"]) {
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
			0px 2px 2px 0px rgba(0, 0, 0, 0.014), 0px 1px 5px 0px rgba(0, 0, 0, 0.012);
	}

	.mat-dialog-container {
		background: $dark-bg-01 !important;
		color: $dark-color !important;
	}
	.mat-tab-labels {
		background: $dark-bg-02 !important;
	}

	.mat-tab-label-active {
		opacity: 1 !important;
	}

	hr {
		border-color: #333333 !important;
	}

	.card-obs {
		.mat-form-field-appearance-fill .mat-form-field-flex {
			background-color: inherit !important;
		}
	}

	.mat-form-field-label {
		color: $dark-color-01;
	}

	.mat-drawer {
		background-color: $dark-bg-01;
		color: $dark-color-01;
	}

	.filter-sidenav .mat-toolbar-single-row {
		background: $dark-bg-02;
		color: $dark-color;
	}

	.mat-drawer.mat-drawer-push {
		background-color: $dark-bg-01;
	}

	.mat-menu-panel {
		background: $dark-bg-01;
	}

	.mat-select-panel {
		background: $dark-bg-01;
	}

	.mat-datepicker-content {
		background-color: $dark-bg-01;
		color: $dark-color-01;
	}

	.mat-paginator {
		background: $dark-bg-02;
		color: $dark-color;
	}

	.mat-table {
		background: $dark-bg-02;
	}

	.mat-expansion-panel {
		border: 2px solid #8282821a !important;
		background: $dark-bg-02 !important;
	}

	.svg-whatsapp svg {
		fill: $dark-color !important;
	}

	a {
		color: mat-color($app-primary);
	}

	::-webkit-scrollbar-thumb {
		background-color: #7777774d !important;
	}
}
